/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">
                <div className="container">
                    <div className="openplft">
                        <img
                            src={
                                post.acf.open_play_image_left.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="nonprofitimg"
                            loading="lazy"
                        />
                    </div>

                    <div
                        className="openprgt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                </div>
            </section>

            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>

            <section className="page-section openphours">
                <div className="container smallestwdt">
                    <div className="twothirdcol">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        <ul className="startxt starlistspacing">
                           <li className="greentxt">Please check our <a class="hourslinka" href="https://www.facebook.com/wrtsdavie/" target="_blank">Facebook</a> for hours or call ahead!</li>
				 
                        </ul>
                       {/* <img className="hoursimg" src="https://werockthespectrumdavie.wrtsfranchise.com/wp-content/uploads/2021/04/wrtsdavieopen.jpeg" />  */}
                        <video
                            className="hoursvideo"
                            src="https://werockthespectrumdavie.wrtsfranchise.com/wp-content/uploads/2025/04/Video.mp4"
                            controls
                            playsInline
                            autoPlay
                            muted
                            loop
                            style={{ width: '100%', maxHeight: '600px' }}
                        />
                    </div>

                    <div className="onethirdcol">
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a>
                        {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>
            </section>

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    <span>*UM-NSU CARD FAMILIES RECEIVE $25 OFF ANY MONTHLY MEMBERSHIP</span>
                           
                </div>
            </section>

            <section className="page-section display_none">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
